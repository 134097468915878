.Collectibles .mainHeader {
    max-height: 190px;
    height: 100%;
    top: 0;
    z-index: 10;
    background: var(--headerColor);
    color: var(--headerFontColor);
    padding: 10px 0;
    border-bottom: none;
    overflow: hidden;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 12%);
    position: sticky;
    top: 0;
}

.Collectibles .headLt .nav-standard-logo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Collectibles .headLt .nav-standard-logo,
.Collectibles .headLt .nav-standard-logo img,
.Collectibles .mainHeader .headLt .headerLogo {
    max-height: 125px;
    object-position: center;
    height: 100%;
}

.Collectibles .headerLogo {
    object-fit: scale-down;
    width: 100%;
    height: 100%;
    max-height: 145px;
    padding-top: 0;
    padding-bottom: 0;
}

.Collectibles .hrToWrpr .popupLgo {
    width: 100%;
    /* max-width: 180px; */
    height: 50px;
    object-fit: scale-down;
    margin-left: auto;
    margin-right: auto;
}

.Collectibles .hrToWrpr .popHr {
    font-size: 25px;
    margin-top: 40px;
}

.Collectibles .hrToWrpr .radCntnr {
    margin-top: 56px;
}

.Collectibles .popRadio {
    margin: 0 15px;
    position: relative;
}

.Collectibles .popRadio input {
    position: absolute;
    visibility: hidden;
}

.Collectibles .popRadio input:checked + label,
.Collectibles .popRadio input:hover + label {
    background-color: var(--primColor);
    border: 1px solid var(--primColor);
    color: white;
}

.Collectibles .popRadio input:checked + label img,
.Collectibles .popRadio input:hover + label img {
    filter: invert(1);
}

.Collectibles .popRadio label {
    width: 200px;
    height: 200px;
    border: 1px solid var(--secColor);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 25px;
    font-weight: 600;
    transition: ease-in-out 0.3s;
}

.Collectibles .popRadio label img {
    margin-bottom: 20px;
}

.Collectibles .hrToWrpr .login-ip-box h1 {
    display: none;
}

.Collectibles .hrToWrpr .popHr span {
    color: var(--primColor);
}

.Collectibles .hrToWrpr .popTgLn {
    color: #393838;
    font-weight: 500;
    font-size: 17px;
}

.Collectibles .hrToWrpr .lgnWthSclMda {
    margin-top: 28px;
}

.Collectibles .hrToWrpr .lgnWthSclMda p {
    font-size: 15px;
    color: #393838;
}

.Collectibles .hrToWrpr .lgnWthSclMda img {
    width: 38px;
    height: 38px;
    margin: 0 15px;
}

.Collectibles .hrToWrpr .orTxt {
    color: rgb(156, 156, 156);
    text-align: center;
    margin-top: 24px;
    font-size: 15px;
}

.Collectibles .hrToWrpr .customCheckbox .MuiFormHelperText-root {
    display: none;
}
.Collectibles .hrToWrpr .customCheckbox .MuiFormControlLabel-root {
    margin-bottom: 0px;
}

.Collectibles .hrToWrpr .fpRm {
    margin-bottom: 20px;
}

.Collectibles .hrToWrpr .fpRm a {
    color: var(--secColor);
}

.Collectibles .hrToWrpr .newAcc a {
    color: var(--primColor);
    font-weight: 500;
}

.Collectibles .hrToWrpr .lgnCntnr .newAcc {
    display: none;
}

.Collectibles .hrToWrpr .imHrTo {
    text-align: center;
}

.Collectibles .hrToWrpr .brSlrWrp {
    max-width: max-content;
    background-color: #f0f0f0;
    border-radius: 10px;
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.Collectibles .hrToWrpr .brSlrWrp .bsrIpt {
    position: relative;
    display: flex;
    align-items: center;
}

.Collectibles .hrToWrpr .brSlrWrp .bsrIpt input {
    position: absolute;
    visibility: hidden;
}

.Collectibles .hrToWrpr .brSlrWrp .bsrIpt label {
    width: 114px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: ease-in-out 0.3s;
    margin: 0;
    border-radius: 7px;
    cursor: pointer;
}

.Collectibles .hrToWrpr .brSlrWrp .bsrIpt input:checked + label,
.Collectibles .hrToWrpr .brSlrWrp .bsrIpt input:hover + label {
    background: var(--primColor);
    color: white;
}

.Collectibles .hrToWrpr .customCheckbox a {
    margin-left: 6px;
    color: var(--primColor);
    font-weight: 500;
}

.Collectibles .hrToWrpr .reg-step1 .primButton {
    margin-top: 20px;
}

.Collectibles .popClrBtn {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 4px;
    color: var(--secColor);
    border: 1px solid var(--secColor);
}

.Collectibles .hdrMenuIcon {
    min-width: unset;
    padding: 0;
}

.Collectibles .mnSideMenu .catgHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    position: relative;
}

.Collectibles .mnSideMenu .catgHead button {
    min-width: auto;
    position: absolute;
    background: var(--primColor);
    top: 40px;
    right: 0;
    width: 35px;
    height: 25px;
    color: #fff;
    border-radius: 25px 0 0 25px;
}

.Collectibles .mnSideMenu .catgHead .nav-standard-logo {
    margin-left: auto;
    margin-right: auto;
    padding-top: 0px;
}

.Collectibles .mnSideMenu .MuiDrawer-paper {
    width: 350px;
}

.Collectibles .navList .navRespLinks a {
    color: black;
    display: flex;
    align-items: center;
    padding: 17px 15px;
    width: 100%;
}

.Collectibles .navList .navRespLinks a.active {
    color: white;
    background: var(--secColor);
}

.Collectibles .navList .navRespLinks .colpsLnk a.active {
    color: white;
    background: var(--secColor);
    opacity: 0.9;
}

.Collectibles .navList .navRespLinks a:hover {
    text-decoration: none;
}

.Collectibles .navList .navRespLinks a .material-icons {
    margin-right: 10px;
    text-decoration: none;
}

.Collectibles .navList .navRespLinks .MuiListItem-root {
    padding: 0px;
}

.Collectibles .navList h4.sdDvdrHdr {
    font-size: 15px;
    padding-left: 16px;
    color: gray;
}

.customDialog.lgnWrpr .MuiPaper-root.MuiDialog-paper {
    min-width: 500px;
}

.customDialog.lgnWrpr .lgnCntnr h1 {
    display: none;
}

.customDialog.lgnWrpr .forgotPasswordCta {
    margin-top: 10px;
}

.Collectibles .nav-standard-top-area-search-inner {
    position: relative;
    width: 100%;
}

.Collectibles .nav-standard-top-area-search-input:first-child {
    border-radius: 100px 0 0 100px;
    background: #f5f5f5;
    /* border-right: 1px solid #9b9b9b; */
    width: 100%;
}

.Collectibles .nav-standard-top-area-search-input {
    border: none;
    padding: 4px 15px 4px 15px;
    color: #333;
    width: 100%;
    display: block;
    height: 45px;
    margin-right: 0;
    background: #fff;
    border-radius: 0px;
    background: #f5f5f5;
}

.Collectibles .nav-standard-top-area-search-inner select {
    border: none;
    padding: 4px 15px 4px 15px;
    color: #333;
    width: 100%;
    display: block;
    height: 45px;
    margin-right: 0;
    background: #fff;
    border-radius: 0px;
    width: 220px;
    font-size: 15px;
    color: #9b9b9b;
    background: #f5f5f5;
    border-left: 1px solid #9b9b9b;
}

.Collectibles .nav-standard-top-area-search-input::placeholder {
    font-size: 15px;
    color: #9b9b9b;
}

.Collectibles .nav-standard-top-area-search-inner select:focus {
    box-shadow: none;
    outline: 0;
}

.Collectibles .nav-standard-top-area-search-input::placeholder {
    color: #333;
}

.Collectibles .nav-standard-top-area-search-input:focus {
    outline: none;
    border-color: #ddd;
}

.Collectibles .nav-standard-top-area-search-sumbit {
    width: 45px;
    height: 45px;
    padding: 0 7px;
    line-height: 30px;
    display: inline-flex;
    text-align: left;
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    background: var(--secColor);
    border: none;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
}

.Collectibles .lgnCntnr .customCheckbox .MuiFormHelperText-root {
    display: block;
}

.Collectibles .crncySlt .customSelect {
    margin-bottom: 0px !important;
}

.Collectibles .navList .navRespLinks .MuiListItem-root.prsnNmTag {
    background: whitesmoke;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 5px;
}

/* @media (min-width: 1024px) {
    .Collectibles .mainHeader .headLt .headerLogo {
        height: 65px;
    }
} */

.Collectibles .topAdtnFtrHeader {
    background: whitesmoke;
    display: flex;
    align-items: center;
}
.Collectibles .under {
    padding: 9px 34px 15px 34px !important;
    color: #4a4a4a;
}
.Collectibles .acc-under {
    color: #646464 !important;
}
.Collectibles .topAdtnFtrHeader .customContainer > div {
    margin-left: 2px;
}

.Collectibles .topAdtnFtrHeader .customSelect {
    margin-bottom: 0px;
    background: #00000026;
}

.Collectibles .topAdtnFtrHeader .customSelect .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
}

.Collectibles .topAdtnFtrHeader .customSelect .MuiOutlinedInput-root {
    border-radius: 0px;
}

.Collectibles .mainHeader .customSelect,
.Collectibles .mainHeader .customInput {
    margin-bottom: 0px;
    max-width: 200px;
    width: 100%;
}
.Collectibles .nav-search-input {
    border-radius: 4px !important;
    min-width: 220px;
    margin-left: 15px;
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    height: 40px;
    background-color: #fff !important;
}
.Collectibles .nav-search-submit {
    border-radius: 4px !important;
    right: -15px !important;
    height: 40px;
    background-color: #2e70b0 !important;
}
.Collectibles .mainHeader .customInput {
    max-width: 300px !important;
    margin-left: 15px;
}
.Collectibles .mainHeader .customInput input[type='text'] {
    padding: 10.5px 14px !important;
}
.Collectibles .mainHeader a {
    color: var(--headerFontColor);
}
.Collectibles .my-main-acc {
    margin-right: 10px;
}
@media (max-width: 1025px) {
    .Collectibles .mainHeader a {
        display: none;
    }
    .WithStyles\(ForwardRef\(Accordion\)\)-root-13:not(:last-child) {
        width: 100%;
    }
    .putcustom {
        display: none !important;
    }
}

@media (max-width: 600px) {
    .Collectibles .multisellerProductDrawer .mssBidIntDet .mssBidMisc .mssBidInfo,
    .Collectibles .multisellerProductDrawer .mssBidIntDet .mssBidMisc .mssBidInfo:not(:last-child) {
        margin-right: auto;
        margin-bottom: 5px;
    }
    .Collectibles .multisellerProductDrawer .mssBidIntDet .mssBidMisc {
        margin-bottom: unset;
    }
    .Collectibles .invoiceCard .card-body .mobLeft {
        text-align: left !important;
    }
    .Collectibles .invoiceCard .card-body .mobChngr {
        display: flex;
        justify-content: flex-start;
        margin-top: 5px;
    }
    .Collectibles .invcActnBtns .MuiButtonBase-root {
        text-align: left;
    }
    .cateGory .customSelect .MuiSelect-outlined.MuiSelect-outlined {
        font-size: 13px;
    }
    .Collectibles .mnSideMenu .catgHead .nav-standard-logo {
        margin-left: 0;
    }
    .Collectibles .mnSideMenu .catgHead {
        padding: 18px 10px;
    }
    .Collectibles .headerLogo {
        height: 100%;
        max-height: 70px;
        padding-top: 0px;
    }
    .Collectibles .mainHeader .headLt .headerLogo {
        width: 100%;
        height: 55px;
        padding-top: 0px;
    }
    .Collectibles .customDialog.lgnWrpr .MuiPaper-root.MuiDialog-paper {
        min-width: fit-content;
        margin: 30px 15px;
    }
    .Collectibles .hrToWrpr .customCheckbox .MuiFormControlLabel-root .MuiTypography-root,
    .Collectibles .hrToWrpr .fpRm a {
        font-size: 14px;
    }
    .Collectibles
        .hrToWrpr
        .customCheckbox
        .MuiFormControlLabel-root
        .MuiTypography-root
        .MuiButtonBase-root {
        padding-left: 0;
        padding-right: 0;
    }
    .Collectibles .customDialog .MuiDialogContent-root {
        padding: 8px 15px;
    }

    .Collectibles .crncySlt .customSelect {
        margin-bottom: 0px !important;
        width: 100% !important;
        margin-right: 0px !important;
    }

    .Collectibles .crncySlt {
        width: 100% !important;
        margin-right: 0px !important;
    }

    .Collectibles .rtPd {
        padding-right: 0px !important;
        padding-left: 0px !important;
    }

    .Collectibles .topAdtnFtrHeader .customContainer > div {
        margin-left: 2px;
        width: 100%;
        max-width: 115px;
    }

    .Collectibles
        .topAdtnFtrHeader
        .customSelect
        .MuiFormControl-root.MuiTextField-root
        .MuiSelect-outlined.MuiSelect-outlined {
        padding: 6px 14px;
    }

    .Collectibles .hrToWrpr .radCntnr {
        justify-content: space-between;
        margin-top: 30px;
    }

    .Collectibles .popRadio label img {
        margin-bottom: 10px;
        max-width: 40px;
    }

    .Collectibles .popRadio,
    .Collectibles .popRadio label {
        width: 48%;
        max-width: 400px;
    }

    .Collectibles .popRadio label {
        height: 100px;
        width: 100%;
        padding: 20px;
        font-size: 18px;
    }

    .Collectibles .popRadio {
        margin: 0 0 15px;
    }
}

@media (max-width: 475px) {
    .Collectibles .mnSideMenu .MuiDrawer-paper {
        width: 260px;
    }
    .Collectibles .multisellerProductDrawer .mssBidMisc {
        align-items: unset;
    }
}
@media screen and (max-width: 992px) {
    .Collectibles .nav-search-input {
        margin-left: 0px;
    }
    .Collectibles .mnSideMenu .MuiDrawer-paper {
        width: 100%;
    }
    .Collectibles .nav-search-submit {
        right: 0px !important;
    }
}
