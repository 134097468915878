.MultiAuction .footer-main-standard {
    padding: 0;
    color: var(--footerFontColor);
    background: var(--footerColor);
    font-size: 15px;
    margin-top: 45px;
}

.footer-main-standard a {
    color: var(--footerFontColor);
}

.footer-copy-right {
    padding: 10px 30px;
    background: var(--footerColor);
}

.footer-copy-right p {
    margin: 0;
}

a.cvc-link {
    font-size: 15px;
}

.footer-main-standard-title {
    margin-bottom: 15px;
    color: var(--primColor);
    font-size: 20px;
    position: relative;
    padding-bottom: 15px;
}

.footer-main-standard-newsletter {
    position: relative;
    margin-bottom: 20px;
    margin-top: 15px;
}

.newsletter-wrapper {
    position: relative;
}
.footer-main-standard-newsletter-input {
    width: 100%;
    border: none;
    border-radius: 45px;
    padding: 13px 26px;
    color: #4a4031;
    font-size: 16px;
    border: 1px solid #ddd;
}

.footer-main-standard-newsletter-submit {
    position: absolute;
    top: 0;
    right: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
    height: 52px;
    font-size: 14px;
    line-height: 33px;
    text-align: center;
    background: var(--primColor);
    color: #ffffff;
    border: none;
    border-top-right-radius: 45px;
    border-bottom-right-radius: 45px;
    padding: 0px 20px;
}

.ftrDtlCntnr .footerMisc {
    max-width: calc(100% - 250px) !important;
    width: 100%;
}

.footer-main-standard-newsletter-submit {
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -o-transition: 0.2s;
    -ms-transition: 0.2s;
    transition: 0.2s;
    line-height: 16px;
}

.footer-main-standard-social-list {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 0px;
    margin-left: -7px;
}

.footer-main-standard-social-list > li {
    display: inline-block;
    margin: 5px 6px;
}

.footer-main-standard-social-list > li > a {
    width: 25px;
    height: 25px;
    border-radius: 25px;
    background: var(--primColor);
    color: #fff;
    opacity: 1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.footer-main-standard-social-list > li > a > svg {
    fill: #fff;
    width: 70%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.3s;
}

.MultiAuction .ftrDtlCntnr {
    text-align: center;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 35px;
    margin-top: 35px;
}

.footer-main-standard-social-list {
    margin: 0;
    width: 100%;
    text-align: right;
}

.MultiAuction .ftrDtlCntnr .footerLogo {
    width: 100%;
    height: 50px;
    object-fit: contain;
    margin: auto;
    border-radius: 10px;
    margin-bottom: 15px;
}

.MultiAuction .footLogoCnt {
    text-align: left;
}

.ftrDtlCntnr .cvc-link {
    color: var(--footerFontColor);
    margin: 10px 0;
    display: inline-flex;
    align-items: center;
}

.ftrDtlCntnr .cvc-link:hover {
    text-decoration: none;
}

.ftrDtlCntnr .cvc-link .material-icons {
    padding-right: 10px;
    color: var(--footerFontColor);
}

.ftrDtlCntnr .fa,
.ftrDtlCntnr .fab,
.ftrDtlCntnr .fal,
.ftrDtlCntnr .far,
.ftrDtlCntnr .fas {
    font-size: inherit;
    margin: 0 auto;
}

.MultiAuction .footerLinks {
    list-style: none;
    padding: 0;
    margin: 15px 0;
    display: flex;
    justify-content: flex-start;
    /* color: #fff; */
}

.MultiAuction .footerLinks li:not(:last-child) {
    margin-right: 0;
}

.MultiAuction .footerLinks ul {
    list-style: none;
    text-align: left;
}

.MultiAuction .footerLinks > * {
    width: 25%;
    text-align: left;
}

.MultiAuction .footerLinks ul li {
    margin-bottom: 12px;
    line-height: 1.67;
}

.MultiAuction .footerLinks li a {
    font-size: 15px;
    font-weight: 500;
}

.MultiAuction footer .copyright {
    font-size: 12px;
    color: var(--footerFontColor);
}

.MultiAuction .footerLinks li a {
    color: inherit;
    font-size: 15px;
    font-weight: 500;
    text-decoration: none;
    -webkit-transition: 200ms color ease-in-out;
    -o-transition: 200ms color ease-in-out;
    transition: 200ms color ease-in-out;
    position: relative;
}

.MultiAuction .footerLinks li a:hover {
    color: var(--primColor);
}

.MultiAuction .footer-main-standard-social-list li a:hover {
    color: white;
}

.MultiAuction .footerLinks li a:after {
    background: none repeat scroll 0 0 transparent;
    bottom: -10px;
    content: '';
    display: block;
    height: 3px;
    left: 50%;
    position: absolute;
    background: var(--primColor);
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

.MultiAuction .footerLinks li a:hover:after {
    width: 100%;
    left: 0;
}

.subscribe-wrapper {
    position: relative;
    margin-bottom: 10px;
}

.subscribe-wrapper .form-control {
    border-radius: 5px;
    background: #fff;
}

.subscribe-wrapper .send-button {
    position: absolute;
    color: #24c0eb;
    right: 8px;
    top: 7px;
}

.MultiAuction .banner-content-wrapper {
    padding-left: 80px;
}

@media (max-width: 991px) {
    .footerLinks {
        display: block;
    }

    .MultiAuction .footerLinks {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .MultiAuction .footerLinks > * {
        width: 100%;
    }
    .MultiAuction .ftrDtlCntnr {
        justify-content: center !important;
    }
}

@media (max-width: 767px) {
    /* html {
        overflow: unset;
    } */
    /* body {
        max-width: 100vw;
        overflow-x: hidden;
    } */
    .ftrDtlCntnr .footerLogo {
        width: 100px;
    }
    .ftrDtlCntnr {
        margin-bottom: 20px !important;
    }

    .MultiAuction .ftrDtlCntnr {
        margin-top: 0;
        flex-flow: column;
        padding: 15px;
    }

    .MultiAuction .footerLinks > * {
        width: 100%;
    }
    .ftrDtlCntnr .footerMisc {
        max-width: 100% !important;
        width: 100%;
    }
    .insta-slider-wrapper .slick-slide img {
        display: block;
        margin: 0 auto;
    }

    .MultiAuction .footerLinks {
        display: grid;
        grid-template-columns: 1fr;
    }

    .MultiAuction .banner-content-wrapper {
        padding: 10px;
    }
}
