.ArtAuction .mainHeader {
    max-height: 190px;
    height: 100%;
    top: 0;
    z-index: 999;
    background: var(--headerColor);
    color: var(--headerFontColor);
    padding: 21px 0;
    border-bottom: none;
    overflow: hidden;
    /* box-shadow: 0px 3px 6px rgb(0 0 0 / 12%); */
    position: sticky;
    top: 35px;
}

.ArtAuction .mainHeader .headermenuTag {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
}

.ArtAuction .headLt .nav-standard-logo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ArtAuction .headLt .nav-standard-logo,
.ArtAuction .headLt .nav-standard-logo img,
.ArtAuction .mainHeader .headLt .headerLogo {
    max-height: 55px;
    object-position: center;
    height: 100%;
}

.ArtAuction .headerLogo {
    object-fit: scale-down;
    width: 100%;
    height: 100%;
    max-height: 145px;
    padding-top: 0;
    padding-bottom: 0;
}

.ArtAuction .hrToWrpr .popupLgo {
    width: 100%;
    /* max-width: 180px; */
    height: 100px;
    object-fit: contain;
    margin-left: auto;
    margin-right: auto;
}
.ArtAuction .add-button-sell {
    padding-right: 50px;
}
.ArtAuction .add-button-sell .primButton button {
    background-color: var(--secColor);
    border: 1px solid var(--secColor);
    border-radius: 50px;
    font-size: 16px;
    font-weight: 400;
    height: 36px;
    padding: 7px 19px;
    text-transform: capitalize;
    min-width: max-content;
}
.ArtAuction .add-button-sell a.borRtt {
    border-right: 1px solid var(--secColor);
}
.ArtAuction .add-button-sell a {
    color: var(--secColor) !important;
    font-size: 16px;
    font-weight: 400;
    padding-left: 19px;
    padding-right: 19px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.ArtAuction .hrToWrpr .popHr {
    font-size: 25px;
    margin-top: 40px;
}

.ArtAuction .hrToWrpr .radCntnr {
    margin-top: 30px;
}

.ArtAuction .popRadio {
    margin: 0 15px;
    position: relative;
}

.ArtAuction .popRadio input {
    position: absolute;
    visibility: hidden;
}

.ArtAuction .popRadio input:checked + label,
.ArtAuction .popRadio input:hover + label {
    background-color: var(--primColor);
    border: 1px solid var(--primColor);
    color: white;
}

.ArtAuction .popRadio input:checked + label img,
.ArtAuction .popRadio input:hover + label img {
    filter: invert(1);
}

.ArtAuction .popRadio label {
    width: 200px;
    height: 200px;
    border: 1px solid var(--secColor);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 25px;
    font-weight: 600;
    transition: ease-in-out 0.3s;
}

.ArtAuction .popRadio label img {
    margin-bottom: 20px;
}

.ArtAuction .hrToWrpr .login-ip-box h1 {
    display: none;
}

.ArtAuction .hrToWrpr .popHr span {
    color: var(--primColor);
}

.ArtAuction .hrToWrpr .popTgLn {
    color: #393838;
    font-weight: 500;
    font-size: 17px;
}

.ArtAuction .hrToWrpr .lgnWthSclMda {
    margin-top: 28px;
}

.ArtAuction .hrToWrpr .lgnWthSclMda p {
    font-size: 15px;
    color: #393838;
}

.ArtAuction .hrToWrpr .lgnWthSclMda img {
    width: 38px;
    height: 38px;
    margin: 0 15px;
}

.ArtAuction .hrToWrpr .orTxt {
    color: rgb(156, 156, 156);
    text-align: center;
    margin-top: 24px;
    font-size: 15px;
}

.ArtAuction .hrToWrpr .customCheckbox .MuiFormHelperText-root {
    display: none;
}
.ArtAuction .hrToWrpr .customCheckbox .MuiFormControlLabel-root {
    margin-bottom: 0px;
}

.ArtAuction .hrToWrpr .fpRm {
    margin-bottom: 20px;
}

.ArtAuction .hrToWrpr .fpRm a {
    color: var(--secColor);
}

.ArtAuction .hrToWrpr .newAcc a {
    color: var(--primColor);
    font-weight: 500;
}

.ArtAuction .hrToWrpr .lgnCntnr .newAcc {
    display: none;
}

.ArtAuction .hrToWrpr .imHrTo {
    text-align: center;
}

.ArtAuction .hrToWrpr .brSlrWrp {
    max-width: max-content;
    background-color: #f0f0f0;
    border-radius: 10px;
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.ArtAuction .hrToWrpr .brSlrWrp .bsrIpt {
    position: relative;
    display: flex;
    align-items: center;
}

.ArtAuction .hrToWrpr .brSlrWrp .bsrIpt input {
    position: absolute;
    visibility: hidden;
}

.ArtAuction .hrToWrpr .brSlrWrp .bsrIpt label {
    width: 114px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: ease-in-out 0.3s;
    margin: 0;
    border-radius: 7px;
    cursor: pointer;
}

.ArtAuction .hrToWrpr .brSlrWrp .bsrIpt input:checked + label,
.ArtAuction .hrToWrpr .brSlrWrp .bsrIpt input:hover + label {
    background: var(--primColor);
    color: white;
}

.ArtAuction .hrToWrpr .customCheckbox a {
    margin-left: 6px;
    color: var(--secColor);
    font-weight: 500;
}

.ArtAuction .hrToWrpr .reg-step1 .primButton {
    margin-top: 20px;
}

.ArtAuction .popClrBtn {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 4px;
    color: var(--secColor);
    border: 1px solid var(--secColor);
}

.ArtAuction .hdrMenuIcon {
    min-width: unset;
    padding: 0;
}

.ArtAuction .mnSideMenu .catgHead .headermenuTag {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
}

.ArtAuction .mnSideMenu .catgHead {
    display: flex;
    justify-content: start;
    align-items: center;
    position: relative;
    padding: 30px 40px;
    padding-left: 60px;
}

.ArtAuction .mnSideMenu .catgHead button {
    padding: 0;
}
.ArtAuction .mnSideMenu .catgHead span {
    font-size: 16px;
    font-weight: 700;
}

.ArtAuction .mnSideMenu .catgHead .nav-standard-logo {
    margin-left: auto;
    margin-right: auto;
    padding-top: 0px;
}

.ArtAuction .mnSideMenu .MuiDrawer-paper {
    width: 385px;
    /* top: 38px; */
}

.ArtAuction .navList .navRespLinks a {
    color: #808080;
    display: flex;
    align-items: center;
    padding: 17px 15px;
    width: 100%;
    padding-left: 60px;
    padding-right: 36px;
    font-size: 22px;
    font-weight: 300;
}

.ArtAuction .DrawerMenuList .MuiPaper-root .MuiListItem-button .MuiTypography-root {
    font-size: 22px;
    font-weight: 300;
    color: #808080;
}
.ArtAuction .DrawerMenuList .MuiPaper-root .MuiListItem-button {
    padding: 0;
    padding: 15px 25px;
}
.ArtAuction .DrawerMenuList .MuiPaper-root .MuiListItem-button:not(:last-child) {
    /* margin-bottom: 33px; */
}

.ArtAuction .DrawerMenuList .MuiPaper-root {
    border-radius: 0;
    padding: 16px 10px;
    min-width: 222px;
}

.ArtAuction .DrawerMenuList .MuiPaper-root .MuiListItem-button a:hover {
    border-color: #000;
    color: transparent;
    border-width: 1px;
    border-bottom: 1px solid #000;
}

.ArtAuction .navList .navRespLinks a .navEndIcon {
    position: absolute;
    right: 30px;
    font-size: 16px;
}
.ArtAuction .navList .navRespLinks a .MuiTypography-root {
    position: relative;
    font-size: 22px;
    font-weight: 300;
}

.ArtAuction .navList .navRespLinks a.active .MuiTypography-root::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    top: auto;
    background-color: #000;
    width: 100%;
    height: 1px;
}

.ArtAuction .navList .navRespLinks a.active svg path,
.ArtAuction .navList .navRespLinks .colpsLnk a.active,
.ArtAuction .navList .navRespLinks a.active {
    background: transparent;
    color: var(--secColor);
    fill: transparent;
    stroke: #000;
    stroke-width: 1px;
}

/* .ArtAuction .navList .navRespLinks a:active svg path,
.ArtAuction .navList .navRespLinks a:active,
.ArtAuction .navList .navRespLinks a:focus svg path,
.ArtAuction .navList .navRespLinks a:not([class='active']) {
    background: var(--secColor);
    color: #fff;
    fill: transparent;
    stroke: #fff;
    stroke-width: 1px;
} */

.ArtAuction .navList .navRespLinks .colpsLnk a.active {
    /* color: white;
    background: var(--secColor);
    opacity: 0.9; */
}

.ArtAuction .navList .navRespLinks a:hover {
    text-decoration: none;
}

.ArtAuction .navList .navRespLinks a .material-icons {
    margin-right: 30px;
    font-size: 18px;
    text-decoration: none;
}

.ArtAuction .navList .navRespLinks .MuiListItem-root {
    padding: 0px;
}

.ArtAuction .navList h4.sdDvdrHdr {
    font-size: 15px;
    padding-left: 16px;
    color: gray;
}

.customDialog.lgnWrpr .MuiPaper-root.MuiDialog-paper {
    min-width: 500px;
}

.customDialog.lgnWrpr .lgnCntnr h1 {
    display: none;
}

.customDialog.lgnWrpr .forgotPasswordCta {
    margin-top: 10px;
}

.ArtAuction .nav-standard-top-area-search-inner {
    position: relative;
    width: 100%;
    border-radius: 100px;
    border: 1px solid #bfbfbf;
}

.ArtAuction .nav-standard-top-area-search-input:first-child {
    border-radius: 100px;
    background: #fff;
    width: 100%;
    /* border: 1px solid rgba(0, 0, 0, 0.23); */
}

.ArtAuction .nav-standard-top-area-search-input::placeholder {
    font-style: italic;
    color: #808080;
    font-size: 16px;
}

.ArtAuction .nav-standard-top-area-search-input {
    border: none;
    padding: 4px 15px 4px 15px;
    color: #333;
    width: 100%;
    display: block;
    height: 45px;
    margin-right: 0;
    background: #fff;
    border-radius: 0px;
    background: #f5f5f5;
}

.ArtAuction .nav-standard-top-area-search-inner select {
    border: none;
    padding: 4px 15px 4px 15px;
    color: #333;
    width: 100%;
    display: block;
    height: 45px;
    margin-right: 0;
    background: #fff;
    border-radius: 0px;
    width: 220px;
    font-size: 15px;
    color: #9b9b9b;
    background: #f5f5f5;
    border-left: 1px solid #9b9b9b;
}

.ArtAuction .nav-standard-top-area-search-input::placeholder {
    font-size: 15px;
    color: #9b9b9b;
}

.ArtAuction .nav-standard-top-area-search-inner select:focus {
    box-shadow: none;
    outline: 0;
}

.ArtAuction .nav-standard-top-area-search-input::placeholder {
    color: #333;
}

.ArtAuction .nav-standard-top-area-search-input:focus {
    outline: none;
    border-color: #ddd;
}

.ArtAuction .nav-standard-top-area-search-sumbit {
    width: 40px;
    height: 40px;
    padding: 0 7px;
    line-height: 30px;
    display: inline-flex;
    text-align: left;
    position: absolute;
    top: 2px;
    right: 6px;
    color: #000;
    background: #fff;
    border: none;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
}

.ArtAuction .lgnCntnr .customCheckbox .MuiFormHelperText-root {
    display: block;
}

.ArtAuction .crncySlt .customSelect {
    margin-bottom: 0px !important;
}

.ArtAuction .navList .navRespLinks .MuiListItem-root.prsnNmTag {
    background: whitesmoke;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 15px 10px 15px 60px;
}

/* @media (min-width: 1024px) {
    .ArtAuction .mainHeader .headLt .headerLogo {
        height: 65px;
    }
} */

.ArtAuction .headLt {
    padding-left: 0;
    padding-right: 0;
}

.ArtAuction .topAdtnFtrHeader {
    z-index: 1200;
    display: flex;
    position: sticky;
    align-items: center;
    background: #0a0a0a;
    top: 0;
}

.ArtAuction .topAdtnFtrHeader .cntUs {
    color: #fff;
    font-size: 14px;
}

.ArtAuction
    .topAdtnFtrHeader
    .customSelect
    .MuiOutlinedInput-root
    .MuiSelect-select:not([multiple])
    option {
    background-color: #000;
}

.ArtAuction
    .topAdtnFtrHeader
    .customSelect
    .MuiOutlinedInput-root
    .MuiSelect-outlined.MuiSelect-outlined {
    color: #fff;
    font-size: 14px;
}

.ArtAuction .topAdtnFtrHeader .customSelect .MuiOutlinedInput-root .MuiSelect-icon path {
    fill: rgb(255, 255, 255) !important;
    d: path('M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z') !important;
    stroke-width: 0;
}

.ArtAuction .topAdtnFtrHeader .customSelect .MuiOutlinedInput-root .MuiSelect-icon {
    color: rgb(255 255 255);
}

.ArtAuction .topAdtnFtrHeader .customContainer > div {
    margin-left: 2px;
}

.ArtAuction .topAdtnFtrHeader .customSelect {
    margin-bottom: 0px;
    background: #00000026;
}

.ArtAuction .topAdtnFtrHeader .customSelect .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
}

.ArtAuction .topAdtnFtrHeader .customSelect .MuiOutlinedInput-root {
    border-radius: 0px;
}

@media (max-width: 1024px) {
    .ArtAuction .add-button-sell {
        display: none !important;
    }
    .ArtAuction .mainHeader .headermenuTag {
        display: none;
    }
}

@media (max-width: 700px) {
    .ArtAuction .navList .navRespLinks a .MuiTypography-root {
        font-size: 20px;
    }
}

@media (max-width: 600px) {
    .ArtAuction .mainHeader {
        top: 28px;
    }

    .ArtAuction .headerLogo {
        height: 100%;
        max-height: 120px;
        padding-top: 10px;
    }
    .ArtAuction .mainHeader .headLt .headerLogo {
        width: 180px;
        height: auto;
        object-fit: contain;
        padding-top: 0px;
    }
    .ArtAuction .customDialog.lgnWrpr .MuiPaper-root.MuiDialog-paper {
        min-width: fit-content;
        margin: 30px 15px;
    }
    .ArtAuction .hrToWrpr .customCheckbox .MuiFormControlLabel-root .MuiTypography-root,
    .ArtAuction .hrToWrpr .fpRm a {
        font-size: 14px;
    }
    .ArtAuction
        .hrToWrpr
        .customCheckbox
        .MuiFormControlLabel-root
        .MuiTypography-root
        .MuiButtonBase-root {
        padding-left: 0;
        padding-right: 0;
    }
    .ArtAuction .customDialog .MuiDialogContent-root {
        padding: 8px 15px;
    }

    .ArtAuction .crncySlt .customSelect {
        margin-bottom: 0px !important;
        width: 100% !important;
        margin-right: 0px !important;
    }

    .ArtAuction .crncySlt {
        width: 100% !important;
        margin-right: 0px !important;
    }

    .ArtAuction .rtPd {
        padding-right: 0px !important;
        padding-left: 0px !important;
    }

    .ArtAuction .topAdtnFtrHeader .customContainer > div {
        margin-left: 2px;
        width: 100%;
        max-width: 100%;
    }

    .ArtAuction
        .topAdtnFtrHeader
        .customSelect
        .MuiFormControl-root.MuiTextField-root
        .MuiSelect-outlined.MuiSelect-outlined {
        padding: 6px 14px;
        padding-right: 30px;
    }

    .ArtAuction .hrToWrpr .radCntnr {
        flex-wrap: wrap;
        margin-top: 30px;
    }

    .ArtAuction .popRadio,
    .ArtAuction .popRadio label {
        width: 100%;
        max-width: 400px;
    }

    .ArtAuction .popRadio label {
        height: 150px;
    }
}

@media (max-width: 475px) {
    .ArtAuction .mnSideMenu .MuiDrawer-paper {
        width: 260px;
    }
}
