.postProductContainer {
    max-width: 930px;
    width: 100%;
    margin: 60px auto;
    padding: 30px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 16%);
}

.postProductContainer .ppTitle {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 35px;
    width: 100%;
    text-align: center;
    color: #2e2e2e;
}

.postProductContainer .ck-content.ck-editor__editable {
    min-height: 290px;
}

.postProductContainer .ck.ck-editor {
    margin-bottom: 25px;
}
.postProductContainer .MuiTabs-fixed {
    text-align: center;
}

.postProductContainer .MuiTabs-fixed .MuiTabs-flexContainer {
    border-bottom: none;
}

.postProductContainer .MuiTabs-fixed .MuiTab-root {
    position: relative;
}

.postProductContainer .MuiTabs-fixed .MuiTab-root.Mui-selected {
    background: #fff;
}

.postProductContainer .MuiTabs-fixed .MuiTab-root.Mui-selected::after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    width: 50%;
    height: 3px;
    background: var(--primColor);
}

.postProductContainer .MuiTab-wrapper {
    font-size: 20px;
    color: #2e2e2e;
    font-weight: 600;
}

.postProductContainer .MuiTabs-root {
    margin-bottom: 45px;
}

.errLst .errValu:last-child .cma {
    display: none;
}

@media (max-width: 600px) {
    .postProductContainer .primButton {
        margin-bottom: 20px;
    }
}
