.custom-modal {
    display: flex;
    align-items: center;
}

.custom-modal .modal-dialog {
    width: 90%;
    max-width: 900px;
}

.custom-modal .modal-header {
    background: #f0eff4;
    border-radius: 10px 10px 0 0;
    height: 60px;
}

.custom-modal .modal-title {
    line-height: 2;
    font-size: 16px;
    text-align: start;
    color: #111;
    font-family: var(--FuturaStdBook);
    text-transform: uppercase;
}

.custom-modal .card {
    width: 100%;
    height: 166px;
    border-radius: 5px;
    background: var(--backgroundColor);
    border: 1px solid #eaeaea;
    max-width: 800px;
    margin: 10px auto;
}

.custom-modal .card-text,
.custom-modal .card-title {
    font-weight: normal;
    font-size: 16px;
    text-align: start;
    color: #111;
}

.custom-modal .modal-body {
    max-height: calc(92vh - 60px);
    overflow: auto;
}

.custom-modal .modal-content {
    border: none;
}

.custom-modal .modal-dialog.modal-lg {
    width: 90%;
    max-width: 900px;
    margin: 0 auto;
}

@media screen and (max-width: 767px) {
    .custom-modal .modal-dialog.modal-lg {
        width: 90%;
        max-width: 700px;
    }
}

@media screen and (max-width: 500px) {
    .custom-modal .modal-dialog.modal-lg {
        width: 90%;
        max-width: 400px;
    }
}
