.search.siteBanner {
    width: 100%;
    height: 350px;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    border-radius: 0;
}

.search.siteBanner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.search.siteBanner h1 {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    margin: 0;
    z-index: 1;
    font-size: 34px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 4px;
    backdrop-filter: blur(5px);
    padding: 10px;
}

.filters-by,
.sort-by {
    margin: 0 -5px;
}

.filters-by .filter-item,
.sort-by .sort-item {
    display: inline-block;
    vertical-align: middle;
    padding: 0 5px;
}

.filters-by .custom-select {
    background: #f9f9f9
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 256 512'%3e%3cpath fill='%23343a40' d='M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z'/%3e%3c/svg%3e")
        no-repeat right 0.5rem center/12px 16px;
    border: 1px solid #ececec;
}

.sort-by .custom-select {
    background: transparent
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 256 512'%3e%3cpath fill='%23343a40' d='M119.5 326.9L3.5 209.1c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0L128 287.3l100.4-102.2c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L136.5 327c-4.7 4.6-12.3 4.6-17-.1z'/%3e%3c/svg%3e")
        no-repeat right 0.5rem center/12px 16px;
    border: 1px solid transparent;
}

.sort-item a {
    display: inline-block;
    vertical-align: middle;
    padding: 5px;
    margin: 0 5px;
    cursor: pointer;
}

.sort-item a:hover,
.sort-item a:focus,
.sort-item a.active {
    color: #ba122b;
}

.search-wrapper {
    margin-bottom: 30px;
}

.auctionListCnt {
    position: relative;
    margin-top: 0;
    background: #fff;
    padding: 30px;
    border-radius: 20px;
}

.auctionListCnt .auctionListTitle {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #656565;
    text-align: center;
    margin-bottom: 30px;
    position: relative;
}

.auctionListCnt .auctionListTitle::before {
    position: absolute;
    content: '';
    width: 30%;
    height: 1px;
    background: #dedede;
    left: 10%;
    top: 10px;
}

.auctionListCnt .auctionListTitle::after {
    position: absolute;
    content: '';
    width: 30%;
    height: 1px;
    background: #dedede;
    right: 10%;
    top: 10px;
}

.auctionGridCnt {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 15px;
    gap: 15px;
}

.gridContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 15px;
}

.MultiAuction .auctionsSearch .searchResults.Grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 15px;
}

.MultiAuction .auctionsSearch .searchResults.Grid .card-body .primButton button {
    border-radius: var(--btnBorderRadius);
}

.MultiAuction .auctionsSearch .searchResults.Grid .list-title {
    text-align: center;
}

.MultiAuction .auctionsSearch .auctionListTitle {
    font-size: 26px;
    color: #2e2e2e;
    font-weight: 500;
    margin: 0 0 15px;
}

.MultiAuction .auctionsSearch .grid-card .grid-img-wrap img {
    height: 260px;
    object-fit: cover;
}

.MultiAuction .grid-card .grid-img-wrap img {
    cursor: pointer;
}

.MultiAuction .auctionsMiscFilter {
    display: flex;
}

.MultiAuction .auctionsMiscFilter .customSelect {
    margin-left: 25px;
    margin-bottom: 0;
}

.MultiAuction .auctionsMiscFilter .gridListToggle button {
    min-width: initial;
    color: #707070;
}

.MultiAuction .auctionsMiscFilter .gridListToggle button.active {
    color: var(--primColor);
}

.aucTypeFilter .MuiButtonBase-root.MuiRadio-root {
    display: none;
}

.aucTypeFilter .MuiFormControlLabel-root {
    width: 100%;
    margin-bottom: 0px !important;
    height: 40px;
    margin: 0;
    width: max-content;
    border-radius: 25px;
}

.aucTypeFilter .MuiFormControlLabel-root:not(:last-child) {
    margin-right: 20px;
}

.aucTypeFilter .MuiFormControlLabel-root .MuiFormControlLabel-label {
    height: 100%;
    width: 100%;
    border-radius: inherit;
    display: flex;
    align-items: center;
    padding-left: 0px;
}

.aucTypeFilter .MuiFormControlLabel-root .MuiFormControlLabel-label {
    background: #fff;
    border: 2px solid var(--primColor);
    height: 100%;
    width: 100%;
    border-radius: inherit;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 600;
    font-size: 15px;
    color: var(--primColor);
}

.aucTypeFilter .MuiFormControlLabel-root .MuiRadio-root.Mui-checked ~ .MuiFormControlLabel-label {
    background: var(--primColor);
    border-color: var(--primColor);
    color: #fff;
    padding-right: 40px;
}

.aucTypeFilter
    .MuiFormControlLabel-root
    .MuiRadio-root.Mui-checked
    ~ .MuiFormControlLabel-label:before {
    position: absolute;
    content: '\e876';
    font-size: 32px;
    font-family: 'Material Icons';
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    font-size: 20px;
}

.MultiAuction .auctionsSearch .nav-standard-top-area-search-input:first-child {
    border: 1px solid #9b9b9b;
    border-radius: 4px;
}

.MultiAuction .auctionsSearch .nav-standard-top-area-search-sumbit {
    background: var(--primColor);
}

.MultiAuction .auctionsSearch .nav-standard-top-area-search-inner {
    width: 280px;
}

.MultiAuction .auctionsSearch .gridListToggle {
    margin-left: auto;
}

@media (min-width: 992px) {
    .nav-sticky .nav-to-sticky {
        z-index: 11;
        border-bottom: 1px solid #ececec;
        box-shadow: none;
    }

    .nav-sticky .search-category-box {
        position: fixed !important;
        border: none;
        background: #fff;
        z-index: 9;
        width: 100%;
        animation: navSticky 0.5s;
        box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
        left: 0;
        padding: 10px 15px;
        top: 50px;
    }

    .nav-sticky {
        padding-top: 124px;
    }
}

@media (max-width: 991px) {
    .gridListToggle {
        position: absolute;
        right: 50px;
        top: 0;
        left: auto;
    }
}

@media (max-width: 767px) {
    .MultiAuction .auctionsMiscFilter .customSelect {
        margin-left: 5px;
    }
    .siteBanner h1 {
        width: max-content;
        text-align: center;
        font-size: 28px;
    }
    .siteBanner {
        height: 300px;
    }
    .MultiAuction .auctionsSearch .searchResults.Grid {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
    .auctionListCnt .auctionListTitle::before,
    .auctionListCnt .auctionListTitle::after {
        width: 20%;
        left: 0;
    }
    .MultiAuction .auctionsSearch .auctionSearchCnt {
        margin-bottom: 0 !important;
    }
}

@media (max-width: 700px) {
    .MultiAuction .auctionsMiscFilter {
        display: flex;
        width: 100%;
        margin: 15px auto;
    }
    .MultiAuction .auctionListTitle {
        text-align: center;
        display: block;
        width: 100%;
        font-size: 18px;
        text-align: left !important;
    }
    .MultiAuction .auctionsMiscFilter .customSelect {
        width: 100%;
    }
    .MultiAuction .toggleRespDrawer.MuiButton-root {
        margin-bottom: 0;
    }
}

@media (max-width: 600px) {
    .MultiAuction .auctionsMiscFilter {
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .MultiAuction .auctionsMiscFilter .customSelect {
        width: 48%;
        margin-left: 0;
    }
    .gridListToggle {
        right: 25px;
    }
    .MultiAuction .auctionsSearch .grid-card .grid-img-wrap img {
        height: 200px;
    }
    .MultiAuction .gridContainer {
        grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
    }
    .MultiAuction .auctionsSearch .auctionListTitle {
        font-size: 20px;
        text-align: center !important;
    }

    .MultiAuction .aucTypeFilter.mblFltrs {
        width: 100%;
    }

    .MultiAuction .aucTypeFilter.mblFltrs .RadioBox .MuiFormGroup-root {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }

    .MultiAuction .aucTypeFilter.mblFltrs .RadioBox .MuiFormControlLabel-root {
        width: 100%;
        margin-right: 0;
    }

    .MultiAuction .auctionsSearch .auctionsMiscFilter .nav-standard-top-area-search {
        width: 100%;
        margin-bottom: 25px;
    }

    .MultiAuction .auctionsSearch .auctionsMiscFilter .nav-standard-top-area-search-inner {
        width: 100%;
    }

    .MultiAuction .auctionsSearch .auctionsMiscFilter .nav-standard-top-area-search-input {
        height: 50px;
    }

    .MultiAuction .auctionsSearch .auctionsMiscFilter .customSelect {
        margin-left: auto;
    }
}

@media (max-width: 450px) {
    .gridListToggle {
        right: 5px;
    }
    .siteBanner {
        height: 300px;
        top: 40%;
    }

    .siteBanner h1 {
        font-size: 18px;
        letter-spacing: 3px;
    }
    .auctionListCnt .auctionListTitle::before,
    .auctionListCnt .auctionListTitle::after {
        width: 15%;
    }
}

@media (max-width: 425px) {
    .MultiAuction .auctionsMiscFilter .customSelect {
        width: 100%;
        margin-bottom: 15px;
    }
}
