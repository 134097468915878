.ArtAuction .topHedr {
    font-size: 40px;
    font-weight: 500;
    text-align: center;
    margin-top: 42px;
    margin-bottom: 29px;
}

.ArtAuction .searchMainCnt {
    background: #fff;
    padding-top: 60px;
}

.ArtAuction .topHedr span {
    color: var(--primColor);
}

.ArtAuction .timerWrapper .timeLabel {
    color: #808080;
    font-size: 16px;
    font-weight: 400;
    text-transform: initial;
}

.ArtAuction .artAuctionTimer h6 {
    font-size: 16px;
    font-weight: 400;
}

.ArtAuction .homeMainCntnr .MuiTabs-scroller {
    text-align: center;
}

.ArtAuction .bioTabs .MuiTabs-flexContainer,
.ArtAuction .homeMainCntnr .MuiTabs-flexContainer {
    border-bottom: none;
}

.ArtAuction .bioTabs .MuiTab-textColorInherit,
.ArtAuction .homeMainCntnr .MuiTab-textColorInherit {
    font-size: 26px;
    font-weight: 400;
    border-bottom: 1px solid transparent;
    padding-left: 30px;
    padding-right: 30px;
}

.ArtAuction .bioTabs .MuiTab-textColorInherit:not(:last-child),
.ArtAuction .homeMainCntnr .MuiTab-textColorInherit:not(:last-child) {
    border-right: 1px solid var(--secColor);
}

.ArtAuction .bioTabs .MuiTab-textColorInherit.Mui-selected,
.ArtAuction .homeMainCntnr .MuiTab-textColorInherit.Mui-selected {
    background: transparent;
    color: var(--secColor);
}

.ArtAuction .bioTabs .MuiTab-textColorInherit.Mui-selected .MuiTab-wrapper,
.ArtAuction .homeMainCntnr .MuiTab-textColorInherit.Mui-selected .MuiTab-wrapper {
    color: var(--secColor);
}

.ArtAuction .grid-card .favChk .lkeTxt {
    font-size: 16px;
    font-weight: 400;
    margin-left: 10px;
    color: #808080;
}

.ArtAuction .grid-card .favChk {
    display: flex;
    align-items: center;
}

.ArtAuction
    .SearchFilter
    .SearchProduct
    .MuiOutlinedInput-root
    .MuiOutlinedInput-input::placeholder {
    color: #808080;
}
.ArtAuction .SearchFilter .SearchProduct .MuiOutlinedInput-root .MuiOutlinedInput-input {
    padding: 14px 25px;
    font-style: italic;
    color: #808080;
    font-size: 16px;
}

.ArtAuction .SearchFilter .SearchProduct {
    margin-bottom: 50px;
}

.ArtAuction .SearchFilter .SearchProduct > .MuiFormControl-root {
    margin-bottom: 20px;
}

.ArtAuction .SearchFilter .SearchProduct {
    display: grid;
    grid-template-columns: 45% 1fr;
    align-items: flex-start;
}

.ArtAuction .SearchFilter .filterBtns .filterOption .MuiButton-root {
    border-radius: 50px;
    background-color: var(--secColor);
    border: 1px solid var(--secColor);
    color: var(--headerColor);
    text-transform: capitalize;
    padding: 13px 20px;
    font-size: 16px;
    font-weight: 400;
    max-height: 47px;
}
.ArtAuction .sortMenu .MuiMenu-paper {
    width: 205px;
}
.ArtAuction .SearchFilter .filterBtns .sortoption .MuiButton-root {
    border: 1px solid var(--secColor);
    color: var(--secColor);
    border-radius: 50px;
    text-transform: capitalize;
    padding: 13px 20px;
    font-size: 16px;
    font-weight: 400;
    max-height: 47px;
}
.ArtAuction .SearchFilter .filterBtns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
}
.ArtAuction .SearchFilter .SearchProduct .searchTags .searchTagsBadge span {
    font-size: 16px;
    cursor: pointer;
    margin-left: 10px;
}
.ArtAuction .SearchFilter .SearchProduct .searchTags {
    margin-top: 30px;
    display: none;
}

.ArtAuction .SearchFilter .SearchProduct .searchTags .searchTagsBadge {
    border: 1px solid #ccc;
    padding: 8px 16px;
    border-radius: 50px;
    width: fit-content;
    font-size: 16px;
    color: #808080;
    font-weight: 400;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-height: 36px;
}
.ArtAuction .SearchFilter .SearchProduct .MuiOutlinedInput-root {
    border-radius: 50px;
}

.ArtAuction .MuiTabs-root {
    margin-bottom: 60px;
}

.ArtAuction .homeAuctions {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    gap: 23px;
}

.ArtAuction .customContainer {
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 60px !important;
    padding-right: 60px !important;
}

.ArtAuction .homeMainCntnr {
    min-height: 60vh;
}

.ArtAuction .homeLoader .loadingCnt {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    grid-gap: 8px;
    gap: 8px;
}

.ArtAuction .homeLoader .loadingCnt .gridViewSkeleton {
    max-width: unset;
}

.ArtAuction .homeLoader .loadingCnt .gridViewSkeleton .skeletonWrapper {
    margin: 0;
    height: 100%;
}

.ArtAuction .homeLoader .loadingCnt .gridViewSkeleton .skeletonWrapper:not(:first-child) {
    display: none;
}

.ArtAuction .homeLoader .loadingCnt .gridViewSkeleton .skeletonWrapper .skeleton.gridImage {
    height: 100%;
}

.searchContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.FoodIndustry .siteBanner {
    width: 100%;
    height: 350px;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    border-radius: 0;
}

.FoodIndustry .siteBanner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.FoodIndustry .siteBanner h1 {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--bannerTextColor);
    margin: 0;
    z-index: 1;
    font-size: 34px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 4px;
    backdrop-filter: blur(5px);
    padding: 10px;
}

.FoodIndustry .siteBanner .bannerContent p {
    font-size: 15px;
    margin-bottom: 45px;
    color: #333;
    width: 100%;
    max-width: 600px;
}

.FoodIndustry .siteBanner .bannerContent button {
    border-radius: 5px;
    width: max-content;
    text-transform: initial;
    font-size: 16px;
}

.FoodIndustry .searchContainer .searchLt {
    position: -webkit-sticky;
    position: sticky;
    top: 115px;
    max-width: 280px;
    width: 100%;
    margin-right: 25px;
}

.searchContainer .searchRt {
    width: 100%;
    width: -webkit-fill-available;
    max-width: calc(100% - 280px);
}

.toggleRespDrawer.MuiButton-root {
    display: none;
}

.FoodIndustry .searchContainer .lotResults.Grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 15px;
}

.FoodIndustry .card.buynow .gridItemAct,
.FoodIndustry .card .gridItemAct,
.FoodIndustry .unauth .card.buynow .timer-text .primButton {
    /* margin-top: 22px; */
}

.FoodIndustry .liquidationBuyCard.auth .mslMisc .biddingCnt button {
    border-radius: 4px !important;
    margin-left: 5px !important;
}

.FoodIndustry .grid-card .hostedBy {
    min-height: 18px;
}

.FoodIndustry .grid-card .gcTimer {
    margin: 5px 0;
}

.ArtAuction .mnCardCntnr .gcTimer {
    color: #0a0a0a;
}

@media (max-width: 1145px) {
    .searchContainer .searchLt {
        max-width: 250px;
    }
}

@media (max-width: 1024px) {
    .toggleRespDrawer.MuiButton-root {
        display: inline-flex;
    }
    .searchContainer .searchLt {
        display: none;
    }

    .searchContainer .searchRt {
        width: 100%;
        max-width: none;
    }

    .responsiveFilterDrawer .MuiPaper-root.MuiDrawer-paper {
        padding: 25px 15px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        width: 100%;
        min-height: 300px;
        max-height: 90vh;
    }
    .responsiveFilterDrawer .filterPanel .fpTitle {
        text-transform: uppercase;
        margin-bottom: 0;
    }

    .responsiveFilterDrawer .filterPanel .MuiAccordionDetails-root {
        width: 100%;
        padding: 10px 0;
        max-height: 45vh;
        overflow-y: auto;
        padding-top: 20px;
    }

    .responsiveFilterDrawer .filterPanel .MuiAccordionDetails-root small {
        padding: 10px;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox {
        width: 100%;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .customCheckbox {
        width: 100%;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 50%;
        z-index: 100;
    }

    .responsiveFilterDrawer .filterPanel .filterAcc .MuiAccordionSummary-root.Mui-expanded {
        background: #fff;
        border-color: var(--primColor) !important;
    }
    .customBreadcrumbs ol {
        width: 100%;
    }
    .filterPanel .priceSlider .MuiSlider-root {
        width: 80%;
        margin-left: 20px;
    }

    .FoodIndustry .mblStckyFilter {
        position: sticky;
        top: 65px;
        background: white;
        z-index: 999;
        display: flex;
        align-items: center;
        margin: 0px !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
        margin-left: -15px !important;
        margin-right: -15px !important;
    }

    .FoodIndustry .mblStckyFilter .customSelect {
        margin-bottom: 0px;
        width: 25%;
    }

    .FoodIndustry .mblStckyFilter .customSelect .MuiSelect-outlined.MuiSelect-outlined {
        padding-right: 33px;
    }

    .FoodIndustry .mblStckyFilter .filterButton {
        width: 24% !important;
        min-width: unset;
        height: 40px;
    }

    .FoodIndustry .mblStckyFilter .filterButton.srchBtn {
        color: var(--primColor);
    }
}

@media (max-width: 992px) {
    .FoodIndustry .searchContainer .lotResults.Grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 15px;
    }
    .grid-card .grid-img-wrap img {
        height: 250px;
    }
    .FoodIndustry .grid-card .grid-img-wrap img {
        height: 200px;
    }
}

@media (max-width: 800px) {
    .ArtAuction .SearchFilter .SearchProduct {
        margin-bottom: 15px;
    }
    .ArtAuction .SearchFilter .SearchProduct .searchTags {
        margin-top: 15px;
    }
    .ArtAuction .SearchFilter {
        grid-template-columns: 1fr;
    }
    .ArtAuction .SearchFilter .filterBtns {
        justify-content: space-between;
    }
    .ArtAuction .SearchFilter .filterBtns .filterOption .MuiButton-root,
    .ArtAuction .SearchFilter .filterBtns .sortoption .MuiButton-root {
        width: 100%;
    }
}

@media (max-width: 600px) {
    .ArtAuction .searchMainCnt {
        padding-top: 30px;
    }

    .ArtAuction .customContainer {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
    .ArtAuction .MuiTabs-root {
        margin-bottom: 25px;
    }
    .ArtAuction .topHedr {
        font-size: 28px;
    }

    .ArtAuction .homeMainCntnr .MuiTab-textColorInherit {
        font-size: 18px;
    }
    .FoodIndustry .gridListToggle {
        display: none;
    }

    .FoodIndustry .searchContainer .lotResults.Grid {
        grid-template-columns: 1fr 1fr;
        gap: 5px;
    }

    .FoodIndustry .liquidationBuyCard .card.grid-card .grid-img-wrap img {
        height: 115px;
    }

    .FoodIndustry .liquidationBuyCard .card.grid-card,
    .FoodIndustry .liquidationAuctions .card.grid-card {
        padding: 5px;
    }

    .FoodIndustry .grid-card .gridItemAct .favoriteCheck {
        width: 30px;
    }

    .FoodIndustry .grid-card .gridItemAct .favoriteCheck label span {
        font-size: 22px;
    }

    .FoodIndustry .grid-card .gridItemAct {
        display: flex;
        align-items: center;
    }

    .FoodIndustry .Grid.liquidationBuyCard .primButton button .MuiButton-label {
        font-size: 12px;
    }

    .FoodIndustry .grid-card .gridItemAct .cartBtn .MuiButton-label .material-icons {
        font-size: 15px;
    }

    .FoodIndustry .grid-card .gridItemAct .cartBtn {
        height: 44px;
        margin: 0 5px;
        width: 41px;
        min-width: unset;
    }

    .FoodIndustry .grid-card .galleryPrice {
        font-size: 14px;
    }

    .FoodIndustry .grid-card .hostedBy {
        min-height: 18px;
        font-size: 13px;
    }

    .FoodIndustry .grid-card .gcTimer {
        margin: 5px 0px;
    }

    .FoodIndustry .mblStckyFilter .filterButton {
        width: max-content !important;
        min-width: unset;
        height: 40px;
    }

    .FoodIndustry
        .responsiveFilterDrawer
        .filterPanel
        .filterCheck
        .MuiFormGroup-root.MuiFormGroup-row,
    .FoodIndustry
        .responsiveFilterDrawer
        .filterPanel
        .filterCheck
        .RadioBox
        .MuiFormControlLabel-root {
        width: 100% !important;
    }

    .ArtAuction .SearchFilter .SearchProduct {
        display: flex;
        align-items: flex-start;
    }

    .ArtAuction .SearchFilter .SearchProduct .filterBtns {
        margin-left: 10px;
    }

    .ArtAuction .SearchFilter .SearchProduct .filterOption {
        margin-left: 10px !important;
    }

    .ArtAuction .SearchFilter .SearchProduct .filterBtns {
        justify-content: flex-start;
    }

    .ArtAuction .SearchFilter .SearchProduct .filterBtns .MuiButton-root {
        padding: 0;
        min-width: max-content;
        width: 45px;
        height: 45px;
    }
}

@media (max-width: 475px) {
    .ArtAuction .homeMainCntnr .MuiTab-textColorInherit {
        margin: 0;
    }
    .ArtAuction .homeAuctions {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
    .toggleRespDrawer.MuiButton-root {
        padding: 3px 10px;
    }

    .ArtAuction .navList .navRespLinks a {
        padding-left: 15px;
        padding-right: 15px;
    }
    .ArtAuction .navList .navRespLinks a .MuiTypography-root {
        font-size: 18px;
    }

    .ArtAuction .navList .navRespLinks a .material-icons {
        margin-right: 20px;
    }
    .ArtAuction .mnSideMenu .catgHead {
        padding: 25px 15px;
    }
}

@media (max-width: 500px) {
    .FoodIndustry .timerWrapper .timeLabel::before {
        left: 10%;
    }
    .FoodIndustry .timerWrapper .timeLabel::after {
        right: 10%;
    }
    .FoodIndustry .card.grid-card .biddingCnt button .material-icons-outlined {
        display: none;
    }
    .ArtAuction .homeMainCntnr .MuiTab-textColorInherit {
        font-size: 15px;
    }
    .ArtAuction .bioTabs .MuiTab-textColorInherit,
    .ArtAuction .homeMainCntnr .MuiTab-textColorInherit {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media (max-width: 400px) {
    .ArtAuction .customContainer {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}
