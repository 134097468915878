.multisellerListCard {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    box-shadow: 0px 3px 30px #eaeaea;
    padding: 10px;
}

.multisellerListCard .grid-img-wrap {
    height: 240px;
    width: 200px;
    position: relative;
}

.liquidationTwo .share-Icon {
    color: var(--primColor);
}

.MultiAuction .multisellerListCard .grid-img-wrap {
    width: 280px;
}

.multisellerListCard .grid-img-wrap .badge.timed {
    background: #e3bb2a;
    color: #333;
    font-size: 15px;
    position: absolute;
    top: 5px;
    left: 5px;
}

.multisellerListCard .grid-img-wrap .badge.live {
    background: #e33f3f;
    color: #fff;
    font-size: 15px;
    position: absolute;
    top: 5px;
    left: 5px;
}

.FoodIndustry .multisellerListCard.buynow .mslMisc .primButton button {
    border-radius: 12px !important;
}

.FoodIndustry .multisellerListCard.auction .mslMisc .primButton button {
    border-radius: 12px !important;
    margin-left: 0px !important;
}

.multisellerListCard.lotCard .grid-img-wrap {
    height: 220px;
    width: 220px;
    cursor: pointer;
}

.multisellerListCard.lotCard .grid-img-wrap > span,
.multisellerListCard .grid-img-wrap .lazy-load-image-background {
    width: inherit !important;
}

.multisellerListCard .grid-img-wrap a {
    display: block;
    height: inherit;
    width: inherit;
}

.multisellerListCard .grid-img-wrap img {
    height: inherit;
    width: inherit;
    object-fit: cover;
    border-radius: 5px;
}

.liquidationTwo .multisellerListCard .grid-img-wrap img {
    object-fit: contain !important;
}

.multisellerListCard .grid-img-wrap img.default,
.multisellerListCard .lotImagesGrid img.default {
    object-fit: contain;
    background: #f9f9f9;
}

.multisellerListCard .mslDesc {
    margin: 0 10px 0 20px;
    width: 100%;
    border-right: 1px solid #e8e8e8;
    padding-right: 15px;
}

.multisellerListCard .mslDesc .listTitle {
    font-size: 20px;
    font-weight: 500;
    color: #2e2e2e;
    margin: 0 0 15px;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.multisellerListCard .mslDesc .listDesc {
    font-size: 15px;
    font-weight: 400;
    color: #717171;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-word;
}

.multisellerListCard .mslMisc {
    min-width: 250px;
    text-align: center;
}

.liquidationTwo.hyperMicro .multisellerListCard .mslMisc .primButton button {
    background-color: var(--secColor);
    border: 1px solid var(--secColor);
    color: var(--primColor);
}

.multisellerListCard .mslMisc .closedText {
    text-align: center;
    display: block;
    font-weight: 500;
    color: #a2a2a2;
}

.MultiSeller .multisellerListCard .mslMisc .closedText {
    margin-bottom: 15px;
}

.FoodIndustry .multisellerListCard .mslMisc .primButton button {
    border-radius: 12px;
}

.multisellerListCard .mslMisc .multiSellerTimerView {
    margin-bottom: 15px;
}

.multisellerListCard .lotImagesGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(65px, 1fr));
    gap: 8px;
    margin-top: 10px;
    overflow: hidden;
    height: 65px;
}

.multisellerListCard .lotImagesGrid img {
    width: 100%;
    height: 65px;
    object-fit: cover;
}

.multisellerListCard.lotCard .lotBidInfo {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 15px;
}

.multisellerListCard.lotCard .lotBidInfo h6 {
    font-size: 16px;
    font-weight: 500;
    color: #2e2e2e;
}

.multisellerListCard.lotCard .lotBidInfo h6:first-child span {
    font-size: 18px;
    font-weight: 600;
    color: var(--primColor);
    margin-top: 5px;
    margin-bottom: 0;
    display: block;
    text-align: left;
}

.multisellerListCard.lotCard .lotBidInfo h6.msUnsold {
    width: 100%;
}

.multisellerListCard.lotCard .lotBidInfo h6.msUnsold span {
    text-align: center;
}

.multisellerListCard.lotCard .viewMoreBtn {
    color: #2e2e2e;
    text-transform: initial;
}

.multisellerListCard.lotCard .viewMoreBtn .MuiButton-label {
    font-weight: 600;
}

.multisellerListCard.lotCard .viewMoreBtn .MuiButton-label .material-icons {
    margin-left: 8px;
}

.multisellerListCard .subLotLine .slAct .biddingCnt button {
    width: max-content;
    margin: 0;
}

.multisellerListCard .subLotLine .slAct .biddingCnt.notLogged button {
    width: 100%;
}

/* .multisellerListCard .subLotLine .slAct .biddingCnt .customInput {
    margin-right: 5px;
} */

.MultiSeller .multisellerListCard .subLotLine .slAct .biddingCnt .customInput {
    margin-right: 0;
    margin-bottom: 5px;
}

.MultiSeller .multisellerListCard.lotCard .lotBidInfo h6 {
    margin-bottom: 0;
}

.HeavyEquipment .multisellerListCard {
    box-shadow: 0px 1px 12px -4px #2e2e2e30;
    padding: 10px 12px;
    border-radius: 3px;
}

.HeavyEquipment .subLotLine .slAct .biddingCnt .customInput {
    margin-bottom: 0;
}

.HeavyEquipment .multisellerListCard .mslDesc .listDesc {
    -webkit-line-clamp: 2;
}

.HeavyEquipment .badgeSpan {
    display: inline-flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 5px;
    flex-wrap: wrap;
}

.HeavyEquipment .multisellerListCard .mslMisc {
    min-width: 200px;
    text-align: center;
}

.HeavyEquipment .AuctionDateRange span {
    font-size: 16px;
    font-weight: 400;
    color: #474747;
}

.HeavyEquipment .AuctionDateRange span:last-child {
    padding-left: 15px;
}

.HeavyEquipment .AuctionDateRange {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 17px;
}

.HeavyEquipment .AuctionDateRange span b {
    font-size: 16px;
    padding-right: 8px;
    font-weight: 500;
}

.HeavyEquipment .multisellerListCard .mslDesc {
    padding-right: 20px;
    /* padding-left: 10px; */
}

.HeavyEquipment .multisellerListCard .mslMisc {
    text-align: left;
    display: flex;
    flex-direction: column;
}

.HeavyEquipment .multisellerListCard .mslMisc .timerCnt > span {
    display: block;
    font-size: 12px;
    color: #474747;
    font-weight: 400;
    margin-bottom: 5px;
}

.HeavyEquipment .auctionListCnt .multisellerListCard .mslMisc .primButton {
    margin-top: auto;
}

.HeavyEquipment .multisellerListCard .mslMisc .primButton button {
    min-height: 40px;
}

.HeavyEquipment .multisellerListCard .mslMisc .timerCnt {
    font-size: 18px;
    font-weight: 600;
    color: var(--primColor);
}

.HeavyEquipment .badgeSpan span {
    text-transform: initial;
}

.HeavyEquipment .badgeSpan > span:not(:last-child) {
    background: #f9f9f9;
    padding: 3px 9px;
    display: inline-block;
    border-radius: 100px;
    font-size: 16px;
    font-weight: 500;
    color: #474747;
    margin-right: 7px;
}

.HeavyEquipment .multisellerListCard .mslDesc .listTitle {
    margin: 0 0 10px;
    cursor: pointer;
}

.HeavyEquipment .grid-card .badgeSpan span:last-child {
    background: #f9f9f9;
    padding: 3px 9px;
    display: inline-block;
    border-radius: 100px;
    font-size: 16px;
    font-weight: 500;
    color: #474747;
    margin-right: 7px;
}

.HeavyEquipment .grid-card .badgeSpan {
    margin-bottom: 23px;
}

.HeavyEquipment .badgeSpan span:last-child {
    font-size: 14px;
    font-weight: 500;
    color: var(--primColor);
    display: flex;
    align-items: center;
    background: unset;
}

.HeavyEquipment .grid-card .AuctionDateRange {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 25px;
}

.HeavyEquipment .grid-card .AuctionDateRange span {
    margin-bottom: 15px;
}

/* .HeavyEquipment .multisellerListCard .mslDesc {
    width: fit-content;
} */

.HeavyEquipment .multisellerListCard.lotCard .grid-img-wrap {
    width: 200px;
}

.HeavyEquipment .multisellerListCard .mslMisc .primButton button {
    background: var(--secColor);
    border-color: var(--secColor);
}

.multisellerListCard .lotCount {
    font-size: 16px;
    color: #fff;
    background: rgba(0, 0, 0, 50%);
    padding: 5px 10px;
    border-radius: 20px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 5px;
    right: 5px;
    z-index: 1;
    user-select: none;
}
.multisellerListCard .lotCount .material-icons {
    padding-right: 8px;
}

.readMore p {
    margin: 0px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    white-space: pre-wrap;
    display: inline;
}

.MultiAuction .hostedBy,
.LiveStock .hostedBy {
    display: none;
}

.LiveStock .estimates {
    display: none;
}

.winBtnWrpr .primButton button {
    background: #0cba28 !important;
    border-color: #0cba28 !important;
}

.outbidBtnWrpr .primButton button {
    background: red !important;
    border-color: red !important;
}

.multisellerListCard .lsListInfo {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    gap: 15px;
    margin-bottom: 25px;
}

.LiveStock .multisellerListCard .lsListInfo {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 15px;
    margin-bottom: 25px;
}

.multisellerListCard .lsListInfo h6 {
    font-size: 14px;
    text-align: left;
    color: #737373;
    margin: 0;
}

.multisellerListCard .lsListInfo h6 span {
    display: block;
    color: #0e131f;
    font-size: 16px;
}

.multisellerListCard .lsListMisc {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    gap: 15px;
}

.multisellerListCard .lsListMisc h6 {
    font-size: 16px;
    text-align: left;
    color: #737373;
    margin: 0;
}

.multisellerListCard .lsListMisc h6 span {
    font-size: 16px;
    text-align: left;
    color: #0e131f;
    margin-left: 15px;
}

.tblImgWrpr {
    position: relative;
}

.tblImgWrpr .favoriteCheck {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 999;
}
.Fundraiser .multisellerListCard .mslDesc .listDesc ul {
    padding-left: 20px;
}

.liquidationTwo .multisellerListCard .mslDesc .listTitle {
    color: var(--primColor);
    text-transform: uppercase;
    font-weight: 500;
    font-size: 21px;
}

.buyTableAucTab {
    position: absolute;
    top: -1px;
    left: 0;
    right: 0;
    z-index: 999;
    width: 100%;
    margin: auto;
}

.liquidationTwo .multisellerListCard .grid-img-wrap {
    height: 200px;
}

.liquidationTwo .multisellerListCard .grid-img-wrap .badge.timed {
    display: none;
}

.liquidationTwo .multisellerListCard .strsEndsDat {
    /* color: var(--primColor); */
    font-weight: 600;
    margin-top: 15px;
}

.liquidationTwo .buyTableAucTab .lctnTxtWrpr {
    padding: 12px 6px;
}
.liquidationTwo .buyTableAucTab .lctnTxtWrpr,
.liquidationTwo .buyTableAucTab .favoriteCheck {
    background-color: #ffffffc2;
    padding: 6px;
}

.text-Yellow {
    color: #ffcc00;
}

/* Responsive */

@media (max-width: 1100px) {
    .HeavyEquipment .AuctionDateRange {
        flex-wrap: wrap;
    }
    .HeavyEquipment .AuctionDateRange span:last-child {
        padding-left: 0;
    }
    .HeavyEquipment .multisellerListCard .mslDesc {
        padding-right: 5px;
    }
    .HeavyEquipment .multisellerListCard .mslMisc {
        min-width: 150px;
    }
}

@media (max-width: 1024px) {
    .HeavyEquipment .AuctionDateRange span b,
    .HeavyEquipment .AuctionDateRange span {
        font-size: 14px;
    }
}

@media (max-width: 992px) {
    .multisellerListCard .mslMisc {
        min-width: 150px;
    }
}

@media (max-width: 990px) {
    .HeavyEquipment .badgeSpan > span:not(:last-child),
    .HeavyEquipment .badgeSpan span {
        font-size: 13px;
    }
    .HeavyEquipment .multisellerListCard .mslDesc .listTitle {
        margin: 0 0 5px;
    }
    .multisellerListCard {
        flex-wrap: wrap;
    }
    .multisellerListCard .mslDesc {
        width: 60%;
        margin: 0;
        padding: 0 10px;
    }
    .multisellerListCard .mslDesc .listTitle {
        font-size: 18px;
        margin: 0 0 8px;
    }
    .multisellerListCard .mslDesc .listDesc {
        font-size: 14px;
        -webkit-line-clamp: 2;
    }
    .multisellerListCard .lotImagesGrid {
        grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
    }
    .multisellerListCard .lotImagesGrid img {
        height: 50px;
    }
    .multisellerListCard .mslMisc {
        /* min-width: 100%; */
        margin-left: 0;
        margin-top: 10px;
    }
}

@media (max-width: 850px) {
    .multisellerListCard .mslDesc {
        width: 48% !important;
    }
}

@media (max-width: 767px) {
    .multisellerListCard .mslMisc {
        min-width: 150px;
        margin-top: 0;
    }
    .multisellerListCard {
        flex-wrap: nowrap;
    }
    .HeavyEquipment .multisellerListCard {
        flex-wrap: wrap;
    }
    .multisellerListCard .lotImagesGrid img:nth-child(n + 11) {
        display: none;
    }
    .multisellerListCard .lotImagesGrid {
        height: auto;
    }

    .LiveStock .multisellerListCard.lotCard .grid-img-wrap {
        height: 220px;
        width: 100%;
        cursor: pointer;
    }

    .LiveStock .multisellerListCard .mslDesc {
        width: 100% !important;
    }

    .LiveStock .multisellerListCard .mslDesc {
        margin: 15px 0;
    }

    .LiveStock .multisellerListCard .mslDesc .listTitle {
        margin-bottom: 20px;
        -webkit-line-clamp: 2;
        line-height: 29px;
    }

    .LiveStock .multisellerListCard .lsListInfo h6 {
        display: flex;
        align-items: center;
    }

    .LiveStock .multisellerListCard .lsListInfo h6 span {
        width: calc(100% - 78px);
        margin-left: auto;
        margin-top: 0;
    }

    .LiveStock .multisellerListCard .subLotLine {
        max-width: unset;
    }

    .LiveStock .multisellerListCard .subLotLine .slAct {
        justify-content: center;
        width: 100%;
    }
}

@media (max-width: 700px) {
    .liquidationTwo .multisellerListCard .grid-img-wrap {
        width: 100%;
    }
    .multisellerListCard .grid-img-wrap {
        width: 35%;
    }
    .multisellerListCard .grid-img-wrap a,
    .multisellerListCard .grid-img-wrap img {
        width: 100%;
    }
    .multisellerListCard .mslDesc {
        width: 65%;
    }
    .multisellerListCard {
        flex-wrap: wrap;
    }
    .multisellerListCard .mslMisc {
        margin-top: 15px;
        min-width: 100%;
    }
}

@media (max-width: 525px) {
    .HeavyEquipment .multisellerListCard .mslDesc {
        width: 100%;
        padding-right: 0;
        padding-left: 0;
        padding-top: 10px;
        margin-top: 0;
    }

    .MultiAuction .multisellerListCard .grid-img-wrap {
        width: 100%;
        background: whitesmoke;
    }

    .MultiAuction .multisellerListCard .grid-img-wrap img {
        object-fit: contain;
    }

    .MultiAuction .multisellerListCard .mslDesc {
        width: 100% !important;
        margin-top: 20px;
        border-right: none;
    }
}

@media (max-width: 425px) {
    .MultiSeller .ftrDtlCntnr {
        flex-direction: column;
    }
    .footer-main-standard-social-list {
        text-align: center;
    }
}

@media (max-width: 425px) {
    .multisellerListCard .grid-img-wrap,
    .multisellerListCard .mslDesc {
        width: 100%;
    }
    .multisellerListCard .mslDesc {
        margin-top: 15px;
    }
}

@media (max-width: 375px) {
    .HeavyEquipment .multisellerListCard .mslMisc .timerCnt {
        font-size: 16px;
    }
}

.tblImgWrpr .soldWrapper {
    background: #00000096;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 59px;
    color: #ffffffa3;
    font-weight: bold;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tblImgWrpr .soldWrapper p {
    margin: 0;
    transform: rotate(324deg);
}
