.hwBdWkrsPopup .mdlTtle {
    background: whitesmoke;
    padding: 8px 10px;
}

.hwBdWkrsPopup .mdlTtle p {
    font-weight: 600;
}

.hwBdWkWrpr {
    line-height: 28px;
    padding: 14px;
}

.hwBdWkWrpr .infoTxt {
    color: rgb(32, 32, 32);
    font-size: 16px;
}

.hwBdWkWrpr .stpsTxt {
    list-style: none;
    padding-left: 33px;
    font-size: 16px;
    margin-top: 30px;
}

.hwBdWkWrpr .stpsTxt li {
    margin-bottom: 33px;
    position: relative;
    z-index: 1;
}

.hwBdWkWrpr .stpsTxt li span {
    color: rgb(215 215 215);
    font-weight: 900;
    font-size: 21px;
    position: absolute;
    left: -35px;
    top: -1px;
    z-index: -1;
}

.hwBdWkWrpr h3 {
    font-size: 24px;
}

.hwBdWkWrpr .MuiButtonBase-root {
    background: whitesmoke;
}

.hwBdWkWrpr .modal-body {
    padding: 30px;
}

.hwBdWkWrpr .topContr {
    border-radius: 10px;
}
