.sd-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 35px;
    width: 100%;
    text-align: center;
    color: #2e2e2e;
    text-transform: uppercase;
}

.sd-count {
    color: #2e2e2e;
    font-size: 50px;
    font-weight: 700;
    margin-bottom: 35px;
    width: 100%;
    text-align: center;
}

.dashboardStats {
    margin-top: 45px;
}

.dashboardStats > h4 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 15px;
}

.dashboardStats .dashboardStatsInner {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 15px;
}

.dashboardStats .dsCard {
    padding: 25px;
    border-radius: 5px;
    box-shadow: 0px 3px 10px #e2e2e2;
    background: #22d587;
}

.ArtAuction .dashboardStats .dsCard {
    padding: 15px 25px;
    min-height: 150px;
    box-shadow: 0px 3px 10px #e2e2e2;
    background: #e6e6e6;
    border: 1px solid #e6e6e6;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.ArtAuction .dashboardStats .dsCard:hover::after {
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0;
    top: 0;
}

.dashboardStats .dsCard h2 {
    font-size: 32px;
    font-weight: 500;
    color: #434343;
}

.ArtAuction .dashboardStats .dsCard::after {
    content: '';
    position: absolute;
    right: auto;
    left: -10px;
    top: -17px;
    width: 100px;
    z-index: -1;
    height: 100px;
    border-radius: 50%;
    background: #000000;
    transition: all 0.3s ease-in-out;
}

.ArtAuction .dashboardStats .dsCard h2 {
    font-size: 42px;
    color: #ffffff;
}

.dashboardStats .dsCard h5 {
    font-size: 25px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 0;
    color: #fff;
    opacity: 0.7;
}

.ArtAuction .dashboardStats .dsCard h5 {
    color: #000;
    font-size: 16px;
    margin-top: 40px;
}

.ArtAuction .dashboardStats .dsCard:hover h5 {
    opacity: 1;
    color: #fff;
    transition: all 0.5s ease-in;
}
